@import "../../../styles/variables";

.cc-button {
  padding: 8px 14px;
  box-sizing: border-box;
  background-color: $red;
  border-radius: 3px;
  border: none;
  color: $white-font;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}
