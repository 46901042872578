// * Base Colors
$cv-blue: #1d325b;
$cv-mid: #1d325b;
$cv-blue-dark: #17233a;
$active-blue: #0290ff;
$active-purple: rgb(98, 81, 133);
$white: #ffffff;
$black: #000;
$grey-light: #ececec;
$grey-dark: #434343;
// $grey-dark-bg: #6c6c6c;
$grey-dark-bg: rgba(196, 196, 196, 0.2);
$grey-border: #6c6c6c;
$white-font: #e0e0e0;
$red: #bc3430;
$red-bright: #eb3324;
$red-bell: #ff0000;
$green: #1e8449;
$green-transparent: rgba(192, 255, 138, 0.2);
$audigy-purple-light: rgb(98, 81, 133);
$audigy-purple: rgb(80, 66, 108);

// * More Semantic aliases
$blue-cv: $cv-blue;
$blue-active: $active-blue;

// * Gradiants
$transparent-to-black: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 1)
);
$purple-gradient: linear-gradient(
  to bottom,
  rgba(80, 66, 108, 0.6),
  rgba(80, 66, 108, 0)
);
$blue-gradient: linear-gradient(
  to bottom,
  rgba(29, 50, 91, 0.6),
  rgba(29, 50, 91, 0)
);
$cv-dark-to-light: linear-gradient(
  158deg,
  rgba(0, 1, 41, 0.79),
  rgba(0, 1, 41, 0.22)
);
$cv-light-to-dark: linear-gradient(
  to bottom,
  rgba(0, 1, 41, 0),
  rgba(0, 1, 41, 0.79)
);
$ag-dark-to-light: linear-gradient(
  158deg,
  rgba(0, 1, 41, 0.79),
  rgba(0, 1, 41, 0.22)
);
$ag-light-to-dark: linear-gradient(
  to bottom,
  rgba(0, 1, 41, 0),
  rgba(0, 1, 41, 0.79)
);

// * Other Variables
$partially-transparent: 0.35;
$opaque-background: rgba(255, 255, 255, 0.12);
$content-max-width: 1100px;
$transition-time-ms: 180ms;
$title-font: "Montserrat", sans-serif;
