@import "../../../styles/variables";

.course-survey-task {
  padding-bottom: 15px;
  box-sizing: border-box;
}

.course-survey-task-question {
  width: 100%;
  font-size: 13px;

  &:nth-of-type(n + 2) {
    padding-top: 20px;
  }
}

.course-survey-task-question-number {
  opacity: $partially-transparent;
  font-weight: bold;

  .course-task-cont.is-survey & {
    animation: revealTask 480ms ease-in-out;
  }

  .course-task-cont.is-survey.hide-task & {
    animation-name: fakeAnimation;
  }
}

.course-survey-task-question-text {
  padding-bottom: 8px;
  box-sizing: border-box;
  font-weight: bold;

  .course-task-cont.is-survey & {
    animation: revealTask 480ms ease-in-out;
  }

  .course-task-cont.is-survey.hide-task & {
    animation-name: fakeAnimation;
  }
}

.course-survey-task-question-answers-cont {
  display: flex;
  flex-direction: column;
}

.course-survey-task-question-answer-wrapper {
  .course-task-cont.is-survey & {
    animation: revealTask 480ms ease-in-out;
  }

  .course-task-cont.is-survey.hide-task & {
    animation-name: fakeAnimation;
  }
}

.course-survey-task-question-answer-cont {
  padding: 8px 6px;
  box-sizing: border-box;
  background-color: transparent;
  min-width: 300px;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  font-size: 14px;

  .course-task-cont.is-survey & {
    animation: revealTask 480ms ease-in-out;
  }

  .course-task-cont.is-survey.hide-task & {
    animation-name: fakeAnimation;
  }

  &.is-checked {
    color: $active-blue;

    .App.audigy & {
      color: $active-purple;
    }
  }

  &.is-checked,
  &:hover {
    background-color: $opaque-background;
    cursor: pointer;
  }

  &[disabled="true"] {
    cursor: initial;

    &:hover {
      cursor: initial;
    }
  }

  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.course-survey-task-question-answer-inp {
  appearance: none;
  border-radius: 50%;
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  border: 1px solid $white-font;
  cursor: inherit;

  .is-checked & {
    background: radial-gradient($active-blue 3px, transparent 3px);

    .App.audigy & {
      background: radial-gradient($active-purple 3px, transparent 3px);
    }
  }

  .is-checked.incorrect-answer & {
    background: radial-gradient($red 3px, transparent 3px);

    .App.audigy & {
      background: radial-gradient($red 3px, transparent 3px);
    }
  }
}

.course-survey-task-question-answer-label {
  padding-left: 12px;
  box-sizing: border-box;
  cursor: inherit;

  .is-checked & {
    color: $active-blue;

    .App.audigy & {
      color: $active-purple;
    }
  }

  .is-checked.incorrect-answer & {
    color: $red;

    .App.audigy & {
      color: $red;
    }
  }
}

.course-survey-task-question-answer-marker {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;

  &.correct-answer {
    color: $active-blue;

    .App.audigy & {
      color: $active-purple;
    }
  }

  &.incorrect-answer {
    color: $red;

    .App.audigy & {
      color: $red;
    }
  }
}

@keyframes revealTask {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}
