@import "../../styles/variables";

.home-main-video-cont {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  z-index: 5;
  position: relative;
}

.home-main-video-overlay {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: $blue-gradient,
    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 80%);
  position: absolute;
  background-size: 100% calc(100vw / 1.5), 100% calc(100vw / 1.75), 100% !important;
  background-position: top left, top left, top left !important;
  background-repeat: no-repeat, no-repeat, no-repeat !important;

  .App.audigy & {
    background: $purple-gradient,
      linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 80%),
      linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 50%);
  }

  @media screen and (max-width: 800px) {
    background-size: 100% 85vh, 100% 100vh, 100% !important;
    background-position: top left, top left !important;
  }
}

.home-main-video {
  // position: absolute;
  // top: 0px;
  // left: 0px;
  // width: 100vw;
  // z-index: 0;
  width: 100%;

  @media screen and (max-width: 800px) {
    width: auto;
    height: 70vh;

    .App.audigy & {
      margin-left: -30vw;
    }

    @media screen and (max-height: 400px) {
      width: 100vw;
      height: auto;
      margin-left: 0px;
    }
  }
}

.home-main-cont {
  padding: 20px 20px 60px;
  z-index: 6;
  min-height: 100vh;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  .App.showing-menu & {
    grid-column-start: 2;
    // grid-area: bodyarea;

    @media screen and (max-width: 800px) {
      grid-column-start: 1;
    }
  }

  // .App & {
  //   background-size: 100% calc(100vw / 1.5), 100% calc(100vw / 1.75), 100% !important;
  //   background-position: top left, top left, top left !important;
  //   background-repeat: no-repeat, no-repeat, no-repeat !important;

  //   @media screen and (max-width: 800px) {
  //     background-size: 100% 85vh, 100% 100vh, auto 70vh !important;
  //     background-position: top left, top left, top right !important;
  //   }
  // }
}
