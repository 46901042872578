@import "../../../styles/variables";

.home-banner-cont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 440px;

  @media screen and (max-width: 800px) {
    padding-top: 40px;
    height: 480px;
  }
}

.home-banner-inner-cont {
  width: 100%;
  max-width: 630px;
  text-align: left;

  .App.audigy & {
    max-width: 780px;
  }
}

.home-banner-title {
  font-size: 45px;
  font-family: $title-font;

  @media screen and (max-width: 800px) {
    font-size: 40px;
    margin-bottom: 20px;
    line-height: 44px;
  }
}

.home-banner-quote,
.home-banner-from {
  font-style: oblique;
}

.home-banner-from {
  padding: 6px 12px;
  box-sizing: border-box;
}

.home-banner-signature-img {
  width: 100%;
  max-width: 142px;
}

.home-banner-play-button {
  margin-top: 30px;
  width: 260px;
}

.home-banner-progress-bar {
  margin-top: 10px;
  width: 260px;
}

.home-banner-welcome-video {
  width: auto;
  max-width: 100%;
}

.home-banner-modal-overlay[data-reach-dialog-overlay] {
}

.home-banner-modal-content[data-reach-dialog-content] {
  animation: zoomIn $transition-time-ms ease-in-out;
  background: $black;
}

.home-banner-modal-inner-cont {
  position: relative;
}

.home-banner-modal-close-btn {
  position: absolute;
  top: -40px;
  right: 0px;
  border: none;
  outline: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    // transform: scale(1);
    transform: scale(1);
  }
}
