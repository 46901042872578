@import "../../../styles/variables";

.cc-progress-bar {
  background-color: $opaque-background;
  height: 5px;
  border-radius: 3px;
}

.cc-progress-complete-bar {
  background-color: $red-bright;
  height: inherit;
  border-radius: inherit;
}
