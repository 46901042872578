@import "../../styles/variables";

.course-main-cont {
  padding: 20px 8px 60px;
  box-sizing: border-box;
  margin: 0px auto;
  max-width: $content-max-width;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  grid-area: bodyarea;

  .App.showing-menu & {
    animation: slideFromLeftNoFade 380ms ease-in-out;
  }

  @media screen and (max-width: 1100px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (max-width: 800px) {
    grid-area: unset;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    animation: none !important;
  }

  // @media screen and (max-width: 450px) {
  //   text-align: center;
  // }
}

.course-top-cont {
  width: 100%;
}

.course-top-courses-link {
  text-decoration: none;
  color: $white-font;
  opacity: $partially-transparent;
  transition: opacity $transition-time-ms ease-in-out;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }
}

.course-top-courses-link-icon {
  margin-right: 8px;
}

.course-info-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.course-info-name {
  padding: 8px 0px;
  box-sizing: border-box;
  font-size: 35px;
}

.course-info-desc {
  padding: 8px 0px;
  box-sizing: border-box;
  font-size: 16px;
}

.course-info-extras-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 11px;
}

.course-info-inner-cont {
  width: 100%;
}

.course-info-favorites-btn {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  color: $white;

  @media screen and (max-width: 450px) {
    padding-top: 12px;
    padding-bottom: 8px;
    width: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
  }

  .course-info-icon {
    margin-right: 8px;
    fill: $white;
  }
}

.course-info-icon {
  margin-right: 6px;
}

.course-info-users-count {
  margin-left: 12px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  color: $white;

  @media screen and (max-width: 450px) {
    width: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
  }

  .course-info-icon {
    fill: $white;
  }
}

@keyframes slideFromLeftNoFade {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0px);
  }
}
