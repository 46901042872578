@import "../../../styles/variables";

$menu-padding-left: 20px;

.cm-menu {
  padding: 100px 8px 20px 0px;
  box-sizing: border-box;
  width: 300px;
  max-width: 95vw;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background-color: transparent;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  text-align: left;
  //   grid-area: menuarea;
  animation: slideFromLeft 400ms ease-in-out;
  z-index: 10;

  @media screen and (max-width: 800px) {
    padding-top: 80px;
    background-color: $cv-blue-dark;
    height: auto;
    min-height: 100vh;
    max-height: unset;
  }

  .App.audigy & {
    @media screen and (max-width: 800px) {
      background-color: $audigy-purple;
    }
  }
}

.cm-menu-inner-cont {
  // height: 100%;
  max-height: 100vh;
  box-sizing: border-box;
  position: sticky;
  width: 100%;
  top: 0px;
  left: 0px;
}

.cm-menu-courses-link {
  padding-left: $menu-padding-left;
  padding-bottom: 20px;
  box-sizing: border-box;
  text-decoration: none;
  color: $white-font;
  opacity: $partially-transparent;
  transition: opacity $transition-time-ms ease-in-out;
  display: flex;
  align-items: center;
  text-align: left;

  &:hover {
    opacity: 1;
  }
}

.cm-menu-courses-link-icon {
  margin-right: 8px;
}

.cm-menu-black-overlay {
  margin: 0px;
  padding: 0px;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;
  border: none;
  z-index: 9;

  @media screen and (min-width: 801px) {
    display: none;
  }
}

.cm-menu-top-bar {
  margin: 0px 0px 40px auto;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-top: 1px solid $opaque-background;
  width: calc(100% - 20px);
}

.cm-menu-courses-submenu-toggle-button-cont {
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 6px 0px 6px $menu-padding-left;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
}

.cm-menu-courses-submenu-toggle-button {
  background-color: transparent;
  border: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
}

.cm-menu-list-title {
  padding-left: $menu-padding-left;
  font-size: 14px;
  color: $opaque-background;
  width: 100%;
  text-align: left;

  .cm-menu-list ~ & {
    margin-top: 40px;
  }
}

.cm-menu-list {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  text-align: left;

  .cm-menu-list-title + & {
    margin-top: 0px !important;
  }

  &:nth-of-type(n + 2) {
    margin-top: 40px;
  }

  &.is-inactive {
    color: $opaque-background;
  }

  &.has-toggle-button {
    position: relative;
    padding-left: $menu-padding-left;
  }
}

.cm-menu-item {
  padding: 2px 0px 2px $menu-padding-left;
  box-sizing: border-box;
  text-align: left;
  position: relative;

  &.is-course-title {
    padding: 0px 0px 20px $menu-padding-left;
  }

  &.has-toggle {
    padding-left: 0px;
    position: relative;
  }

  .has-toggle-button & {
    padding-left: 30px;
  }

  .has-toggle-button &.is-course-title {
    padding-left: $menu-padding-left;
  }
}

.cm-menu-item-icon {
  position: absolute;
  left: 0px;
  top: 6px;

  &.cur-task-icon {
    top: 9px;
  }

  .is-complete & {
    fill: $opaque-background;
  }
}

.cm-menu-item-subtitle {
  width: 100%;
  font-size: 10px;
  color: $active-blue;

  .App.audigy & {
    color: $active-purple;
  }

  .is-inactive & {
    color: inherit;
  }
}

.cm-menu-item-title {
  font-size: 15px;
  font-family: $title-font;
}

.cm-menu-list-toggle-button {
  padding: 2px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 0px 3px 3px 0px;
  background-color: $active-blue;
  position: absolute;
  left: 0px;
  top: 0px;
  border: none;

  .App.audigy & {
    background-color: $active-purple;
  }

  .is-inactive & {
    background-color: $opaque-background;
  }
}

.cm-menu-list-toggle-button-icon {
}

.cm-menu-item-link {
  padding: 2px 8px 2px 0px;
  box-sizing: border-box;
  text-decoration: inherit;
  color: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  display: block;
  text-align: left;

  &.is-link-button {
    border: none;
    background-color: transparent;

    .is-inactive & {
      cursor: initial !important;
    }
  }

  .is-complete & {
    color: $opaque-background;
  }

  &:hover {
    cursor: pointer;
  }

  &.current-task {
    color: $active-blue;

    .App.audigy & {
      color: $active-purple;
    }
  }

  &:active {
    color: $active-blue;

    .App.audigy & {
      color: $active-purple;
    }
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}
