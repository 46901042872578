@import "../../../styles/variables";

.cm-nav {
  padding: 20px 12px 16px 12px;
  box-sizing: border-box;
  width: 100%;
  //   position: s
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: $white-font;
  grid-area: navarea;
  z-index: 11;

  button {
    // padding: 0px;
    margin: 0px;
    border: none;
    background-color: transparent;
    color: inherit;

    &:hover {
      cursor: pointer;
    }
  }
}

// * LEFT =======
.cm-nav-left {
  display: flex;
  align-items: center;
}

.cm-nav-menu-btn {
  transition: color $transition-time-ms ease-in-out;

  &.menu-is-open {
    color: $active-blue;

    .App.audigy & {
      color: $active-purple;
    }
  }
}

.cm-nav-logo {
  padding: 0px 6px;
  box-sizing: border-box;
  max-height: 34px;

  &.cv-logo {
    padding-right: 10px;
  }

  &.tryyb-logo {
    padding-left: 10px;
    // margin-left: 8px;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}

.cm-nav-plus {
  font-size: 30px;
  font-weight: lighter;
  // color: $opaque-background;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

// * RIGHT ======
.cm-nav-right {
  display: flex;
  align-items: center;
}

button.cm-nav-text-btn {
  // font-size: 14px;
  color: $active-blue;
  font-weight: bold;

  .App.audigy & {
    color: $active-purple;
  }

  @media screen and (max-width: 475px) {
    display: none;
  }
}

.cm-nav-search-btn {
  padding: 0px 8px;
  box-sizing: border-box;
}

.cm-nav-userthumb-cont {
  margin: 0px 8px 0px 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  //   background-color: $grey-dark;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    margin: 0px 8px;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
}

.cm-nav-userthumb {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.cm-nav-user-points-count {
  padding: 0px 8px 0px 4px;
  box-sizing: border-box;
  font-family: $title-font;

  @media screen and (max-width: 800px) {
    padding: 0px 2px 0px 2px;
    font-size: 12px;
  }
}

.cm-nav-bell-cont {
  margin-left: 16px;
  padding: 0px 4px 0px 4px;
  font-weight: bolder;
  position: relative;

  &::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% + 2px);
    background-color: $red-bell;
    z-index: 13;
  }

  @media screen and (max-width: 800px) {
    margin-left: 8px;
  }
}

.cm-nav-bell {
}
