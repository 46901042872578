@import "../../../styles/variables";

.home-categories-category-cont {
  padding: 20px 0px 0px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.home-categories-category-title {
  margin: 0px;
  padding: 0px 0px 0px 12px;
  box-sizing: border-box;
  width: 100%;
  font-size: 13px;
  text-align: left;
}

.home-categories-category-courses-cont {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.home-categories-category-course {
  padding: 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 357px;
  background-color: transparent;
  //   transition: background-color $transition-time-ms ease-in-out;
  border-radius: 5px;
  text-decoration: none;
  color: $white-font;

  &:nth-of-type(n + 2) {
    margin-left: 30px;
  }

  &:hover {
    background-size: 100% 100%, 100% 100%;
    background: $cv-dark-to-light, $cv-blue;
  }

  .App.audigy &:hover {
    background: $cv-dark-to-light, $audigy-purple;
  }
}

.home-categories-category-course-image-cont {
  height: 170px;
  width: 321px;
  // background: url("/cv-logo.png");
  background-color: $cv-blue-dark;
  // background-size: 75%;
  // background-position: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  .App.audigy & {
    background-color: $audigy-purple;
  }
}

.home-categories-category-course-image {
  width: 100%;
  min-width: 105%;
}

.home-categories-category-course-name {
  margin: 0px;
  padding: 10px 0px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: $title-font;
  font-weight: bold;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  text-decoration: none;
}

.home-category-course-info-cont {
  padding: 8px 10px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 0;

  .home-categories-category-course:hover & {
    opacity: 1;
  }
}

.home-category-course-info-item-cont {
  padding: 0px 10px;
  box-sizing: border-box;
}

.home-category-course-info-item-icon {
  margin-right: 6px;
}

.home-category-course-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0px);
  opacity: 0;
  border-radius: 50%;
  background-color: $cv-mid;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);

  .App.audigy & {
    background-color: $audigy-purple;
  }

  .home-categories-category-course:hover & {
    opacity: 1;
    animation: zoomInSlightly 120ms ease-in-out;
  }
}

.home-categories-category-course-desc {
  font-size: 14px;
  max-height: 44px;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;
  transition: opacity $transition-time-ms ease-in-out;
  text-align: left;
  text-decoration: inherit;
  color: inherit;

  .home-categories-category-course:hover & {
    opacity: 1;
  }
}

.home-categories-category-course-desc-text {
  text-overflow: ellipsis;
}

@keyframes zoomInSlightly {
  0% {
    transform-origin: center;
    transform: translate3d(-50%, -50%, 0px) scale(0.75);
  }
  100% {
    transform: translate3d(-50%, -50%, 0px) scale(1);
  }
}
