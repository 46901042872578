@import "../../../styles/variables";

.course-videotask-video {
  margin: 0px auto;
  width: 100%;
  //   max-width: 900px;
  max-width: 100%;
  //   height: 425px;
  border-radius: 3px;
  background-color: $grey-dark;
  display: flex;
  align-items: center;
  justify-content: center;
}
