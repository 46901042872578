@import "../../../styles/variables";

.file-task-cont {
  width: 100%;
  display: flex;
  //   justify-content: space-between;
}

.file-task-btn {
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: $grey-dark-bg;
  border: 1px solid $grey-border;
  color: $white;
  transform: scale(1);
  transition: transform $transition-time-ms ease-in-out,
    background-color $transition-time-ms ease-in-out,
    color $transition-time-ms ease-in-out, fill $transition-time-ms ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }

  &.acknowledged {
    border-color: $green;
    background-color: $green-transparent;
    color: $green;

    .file-task-read-button-icon {
      fill: $green;
    }
  }

  @media screen and (max-width: 450px) {
    padding: 8px 12px;
  }
}

.file-task-read-button {
  //   margin-left: auto;
  margin-right: 10px;
  padding: 15px 6px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 112px;

  &.acknowledged {
    border-color: $green;
  }

  @media screen and (max-width: 450px) {
    margin-right: 5px;
    padding: 8px 6px;
  }
}

.file-task-read-button-text {
  font-size: 8px;
}

.file-task-download-button {
  margin-left: 10px;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  max-width: 550px;

  @media screen and (max-width: 450px) {
    margin-left: 5px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.file-task-file-button-icon {
}

.file-task-download-button-info {
  margin: 0px auto;
  padding: 0px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media screen and (max-width: 450px) {
    padding: 0px 12px;
  }
}

.file-task-download-button-info-name {
  font-size: 23px;
  width: 100%;

  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
}

.file-task-download-button-info-desc {
  font-size: 12px;
  width: 100%;
}

.file-task-download-button-icon {
}
