@import "./styles/variables";

// * CSS RESET
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

// * =========

@font-face {
  font-family: "Montserrat";
  src: url("/Montserrat-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat_Bold";
  src: url("/Montserrat-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

body {
  // background: $transparent-to-black, $blue-gradient;
  // background-size: cover, 100% 100%, 100% 50%;
  // background-repeat: repeat, no-repeat, no-repeat;
  background-color: $cv-blue;

  &.audigy-app {
    background-color: $audigy-purple;
  }
}

.App {
  padding: 0px 0px;
  box-sizing: border-box;
  background: $transparent-to-black, $blue-gradient;
  background-size: cover, 100% 100%, 100% 50%;
  background-repeat: repeat, no-repeat, no-repeat;
  min-height: 100vh;
  background-color: $black;
  color: $white-font;
  // *
  // * Grid Stuff =========
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 80px auto;
  grid-template-areas: "navarea navarea" "bodyarea bodyarea";
  overflow-x: hidden;

  &.audigy {
    background: $transparent-to-black, $purple-gradient;
  }

  &.showing-menu {
    grid-template-areas: "navarea navarea" "menuarea bodyarea";
  }
}

.title-font {
  font-family: "Montserrat", sans-serif;
  //   font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
  //     Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

// * Reach Dialog style overrides ==========
reach-portal {
  div[data-reach-dialog-overlay] {
    background: linear-gradient(
        158deg,
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.45)
      ),
      rgba(0, 0, 0, 0.45);
    background-size: 100% 100%, 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
  }

  div[data-reach-dialog-content] {
    padding: 10px;
    margin: auto auto;
    width: auto;
    max-width: 95vw;
    max-height: 95vh;
    border-radius: 5px;
  }
}
