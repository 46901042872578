@import "../../../styles/variables";

.course-task-cont {
  margin: 0px auto;
  padding-top: 10px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.is-file,
  &.is-video {
    animation: revealTask 480ms ease-in-out;
  }

  &.hide-task {
    opacity: 0;
    animation-name: fakeAnimation;
  }

  &:nth-of-type(n + 2) {
    padding-top: 35px;
  }

  @media screen and (max-width: 450px) {
    animation-duration: 380ms;
  }

  // &.is-survey {
  //   animation-name: fakeAnimation;
  // }
}

.course-task-name {
  width: 100%;
  font-size: 28px;

  .course-task-cont.is-survey & {
    animation: revealTask 480ms ease-in-out;

    @media screen and (max-width: 450px) {
      animation-duration: 380ms;
    }
  }

  .course-task-cont.is-survey.hide-task & {
    animation-name: fakeAnimation;
  }
}

.course-task-desc {
  padding-bottom: 30px;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;

  .course-task-cont.is-survey & {
    animation: revealTask 480ms ease-in-out;

    @media screen and (max-width: 450px) {
      animation-duration: 380ms;
    }
  }

  .course-task-cont.is-survey.hide-task & {
    animation-name: fakeAnimation;
  }
}

@keyframes revealTask {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}
